













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.padding-custom {
  padding-top: 142px;
  padding-bottom: 3em;
  @media (max-width: 575px) {
    padding-top: 40px;
    padding-bottom: 2em;
  }
}
.home {
  font-family: 'Space Mono', monospace;
  .position-relative {
    position: relative;
    z-index: 10;
  }
  .animate-btn {
    transition: 0.5s;
  }

  .animate-btn:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  #banner {
    .container-fluid {
      padding-left: 0;
      padding-right: 0;
      position: relative;
      z-index: 10;
    }

    .animate-logo {
      animation: tokencoin 1.8s linear infinite;
      transition: 0.5s;
    }

    .animate-logo:hover {
      transform: scale(1.1);
    }
    .text-heading {
      color: #00ff00;
      text-shadow: 0px 0px 5px #00ff00;
    }
    .bg-image {
      width: 100%;
      height: auto;
      background-image: url('~@/assets/images/Header/Artboard-2.1.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .banner-content {
      font-size: 4rem;
      font-weight: bolder;
      // background-color: rgba(5, 5, 5, 0.633);
      width: auto;
      text-align: center;
      line-height: 100%;
      padding: 0px 0px 10px 0px;

      @media (max-width: 991px) {
        padding: 0px 10px 10px 10px;
        font-size: 3.5rem;
      }
      @media (max-width: 767px) {
        font-size: 3rem;
      }
      @media (max-width: 767px) {
        font-size: 25px;
      }
      .banner-content2 {
        background-color: rgba(5, 5, 5, 0.633);
        border: 2px solid #601203;
        width: 700px;
        height: 100%;
        min-height: 70px;
        margin: 0 auto;
        @media (max-width: 991px) {
          min-height: 80px;
        }
        @media (max-width: 767px) {
          width: 500px;
          min-height: 60px;
        }
        @media (max-width: 575px) {
          width: 100%;
          min-height: 60px;
        }
        .typing {
          font-size: 3rem;
          color: #00ff00;
          text-shadow: 0px 0px 5px #00ff00;
          font-weight: bolder;
          @media (max-width: 991px) {
            font-size: 3rem;
          }
          @media (max-width: 767px) {
            font-size: 25px;
            margin-bottom: 0px;
          }
          @media (max-width: 375px) {
            font-size: 20px;
          }
        }
        .typed-cursor {
          font-size: 3rem;
          color: #00ff00;
          background: none !important;
          text-shadow: 0px 0px 5px #00ff00;
          font-weight: bolder;
          padding: 0px;
          margin-top: -30px;
          @media (max-width: 767px) {
            font-size: 25px;
            margin-top: 0px;
          }
          @media (max-width: 375px) {
            font-size: 20px;
          }
        }
      }
    }

    .trailer-btn {
      color: rgb(137, 248, 137);
      font-size: 1.2rem;
      font-weight: bold;
      background-color: rgba(255, 0, 0, 0.204);
      padding: 0.7rem 3rem;
      text-align: center;
      transition: 0.5s;
      line-height: normal;
      border: 2px solid #00ff00;
    }

    .trailer-btn:hover {
      transform: scale(1.1);
      cursor: pointer;
    }

    .banner-btn {
      width: 350px;
      height: auto;
      .ml-custom-button-banner {
        margin-left: 100px;
        @media (max-width: 1699px) {
          margin-left: 50px;
        }
        @media (max-width: 1399px) {
          margin-left: 0px;
        }
      }
      .mr-custom-button-banner {
        margin-right: 100px;
        @media (max-width: 1699px) {
          margin-right: 50px;
        }
        @media (max-width: 1399px) {
          margin-right: 0px;
        }
      }
    }
    .footer-banner {
      background-image: url('~@/assets/images/Header/Artboard-35_1.png');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 100%;
      min-height: 200px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      @media (max-width: 575px) {
        flex-wrap: wrap;
      }
      .item {
        .item-number {
          color: #00ff00;
          text-shadow: 0px 0px 5px #00ff00;
          font-size: 50px;
          font-weight: bold;
          @media (max-width: 767px) {
            font-size: 35px;
          }
        }
        .item-text {
          color: #ffffff;
          text-shadow: -1px -1px 20px #00ff00;
          font-weight: 600;
          background-color: #601203;
          line-height: 100%;
          padding-bottom: 10px;
          font-size: 20px;
          word-spacing: 10px;
          @media (max-width: 767px) {
            font-size: 16px;
            word-spacing: 5px;
          }
        }
      }
    }
  }
  #about {
    &.bg-image {
      width: 100%;
      height: auto;
      background-image: url('~@/assets/images/Artboard-32.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      .container {
        position: relative;
        z-index: 10;
      }
      p {
        font-size: 22px;
      }
    }
  }
  #content {
    background-image: url('~@/assets/images/Artboard-24.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    .bg-image-heroland-content {
      .heroland-base {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .bg-content-typing {
          background-color: #00ff00;
          width: 500px;
          height: 100%;
          min-height: 70px;
          position: relative;
          z-index: 2;
          align-items: center;
          @media (max-width: 575px) {
            width: 100%;
            min-height: 50px;
          }
          .typed-element {
            @media (max-width: 575px) {
              width: 100%;
              justify-content: center;
            }
            .typing {
              color: #000000;
              text-shadow: -1px -1px 20px #00ff00;
              font-weight: 700;
              font-size: 50px;
              width: fit-content;
              line-height: 100%;
              margin-bottom: 0px;
              @media (max-width: 575px) {
                font-size: 40px;
              }
              @media (max-width: 414px) {
                font-size: 30px;
              }
              @media (max-width: 375px) {
                font-size: 25px;
              }
            }
            .typed-cursor {
              font-size: 50px;
              color: #000000;
              background: none !important;
              text-shadow: -1px -1px 20px #00ff00;
              font-weight: 700;
              padding: 0px;
              margin-top: 0px;
              @media (max-width: 575px) {
                font-size: 40px;
              }
              @media (max-width: 414px) {
                font-size: 30px;
              }
              @media (max-width: 375px) {
                font-size: 25px;
              }
            }
          }
        }
        .heroland-base-carousel {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: url('~@/assets/images/Artboard-82.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          height: 300px;
          margin-top: -20px;
          .VueCarousel {
            width: 1200px;
            z-index: 10;
            @media (max-width: 1400px) {
              width: 1000px;
            }
            @media (max-width: 1200px) {
              width: 800px;
            }
            @media (max-width: 991px) {
              width: 500px;
            }
            @media (max-width: 575px) {
              width: 300px;
            }
            @media (max-width: 375px) {
              width: 250px;
            }
            .VueCarousel-wrapper {
              .VueCarousel-inner {
                .VueCarousel-slide {
                  .box-img {
                    text-align: center;
                  }
                }
              }
            }
            .VueCarousel-navigation-button {
              outline: none;
              img {
                @media (max-width: 575px) {
                  width: 30px;
                }
              }
            }
          }
        }
      }
      .electric-base {
        position: relative;
        z-index: 10;
        .img-electric-base {
          width: 500px;
          height: auto;
          @media (max-width: 1199px) {
            width: 400px;
          }
          @media (max-width: 991px) {
            width: 450px;
          }
        }
        .electric-base-content {
          font-size: 22px;
        }
        .base-title {
          width: fit-content;
          height: 70px;
          font-size: 50px;
          font-weight: bold;
          background-color: #000000;
          color: #ffffff;
          text-shadow: -1px -1px 20px #00ff00;
          line-height: 100%;
        }
      }
      .bg-content-typing {
        background-color: #00ff00;
        width: 600px;
        height: 100%;
        min-height: 70px;
        position: relative;
        z-index: 2;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 100px;
        @media (max-width: 575px) {
          width: 100%;
          min-height: 50px;
          margin-bottom: 30px;
        }
        .typed-element {
          @media (max-width: 575px) {
            width: 100%;
            justify-content: center;
          }
          .typing {
            color: #000000;
            text-shadow: -1px -1px 20px #00ff00;
            font-weight: 700;
            font-size: 50px;
            width: fit-content;
            line-height: 100%;
            margin-bottom: 0px;
            @media (max-width: 575px) {
              font-size: 35px;
            }
            @media (max-width: 414px) {
              font-size: 30px;
            }
            @media (max-width: 375px) {
              font-size: 25px;
            }
          }
          .typed-cursor {
            font-size: 50px;
            color: #000000;
            background: none !important;
            text-shadow: -1px -1px 20px #00ff00;
            font-weight: 700;
            padding: 0px;
            margin-top: 0px;
            @media (max-width: 575px) {
              font-size: 35px;
            }
            @media (max-width: 414px) {
              font-size: 30px;
            }
            @media (max-width: 375px) {
              font-size: 25px;
            }
          }
        }
      }
      #benefit {
        position: relative;
        z-index: 10;
      }
    }
    .bg-image-heroland-content2 {
      p {
        font-size: 22px;
        letter-spacing: 2px;
      }
      .bg-color {
        background-color: #00000092;
        .landownerbenefit-content {
          background-image: url('~@/assets/images/Artboard 25.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100% 100%;
          padding: 50px 100px 20px 100px;
          width: 1000px;
          margin: 0 auto;
          top: -50px;
          position: relative;
          z-index: 50;
          @media (max-width: 1099px) {
            width: 900px;
          }
          @media (max-width: 991px) {
            width: 700px;
            top: 0px;
          }
          @media (max-width: 767px) {
            width: 500px;
          }
          @media (max-width: 575px) {
            width: 100%;
            padding: 20px 15px 20px 15px;
          }
          .landownerbenefit-item {
            width: 100%;
            display: flex;
            @media (max-width: 991px) {
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            .landownerbenefit-img {
              width: 300px;
              height: 300px;
              margin-right: 50px;
              background-image: url('~@/assets/images/icons-benefits/bg-icon.png');
              background-repeat: no-repeat;
              background-position: center;
              background-size: 100% 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              @media (max-width: 991px) {
                margin-right: 0px;
              }
              @media (max-width: 767px) {
                width: 250px;
                height: 250px;
              }
              img {
                width: 200px;
                height: auto;
                filter: drop-shadow(0 0 0.75rem #34f834);
                @media (max-width: 767px) {
                  width: 150px;
                }
              }
            }
            .landownerbenefit-text {
              width: calc(100% - 350px);
              padding-top: 20px;
              font-size: 30px;
              @media (max-width: 991px) {
                width: 100%;
                text-align: center;
              }
              @media (max-width: 767px) {
                font-size: 20px;
              }
              @media (max-width: 575px) {
                max-width: 300px;
                text-align: center;
              }
            }
          }
          .VueCarousel-pagination {
            .VueCarousel-dot {
              border-radius: unset !important;
              background-color: #00ff0041 !important;
              outline: none !important;
              &.VueCarousel-dot--active {
                background-color: #00ff00 !important;
              }
            }
          }
        }
        .button-group {
          img {
            margin: 0px 15px;
            width: auto;
            height: 80px;
            @media (max-width: 480px) {
              height: 55px;
              margin: 0px 10px;
            }
          }
        }
        #token {
          position: relative;
          z-index: 10;
        }
      }
      .bg-chart {
        background-image: url('~@/assets/images/Artboard-82.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        .percent {
          padding-left: 40px;
          @media (max-width: 991px) {
            padding-left: 0px;
          }
          h2 {
            font-weight: bold;
          }
        }
        .text {
          p {
            font-size: 18px !important;
            letter-spacing: 0px;
          }
        }
        .percent1 {
          color: #ffffff;
        }
        .percent2 {
          color: #00ff60;
        }
        .percent3 {
          color: #7dd2cb;
        }
        .percent4 {
          color: #c30000;
        }
        .percent5 {
          color: #d56969;
        }
        .percent6 {
          color: #acdc00;
        }
        .percent7 {
          color: #849040;
        }
        .percent8 {
          color: #586198;
        }
      }
    }
    .bg-image-heroland-content3 {
      padding: 100px 0px;
      .bg-roadmap {
        background-image: url('~@/assets/images/Artboard 41.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 50px 0px;
      }
      .bg-content-typing {
        background-color: #00ff00;
        width: fit-content;
        height: 100%;
        position: relative;
        z-index: 2;
        align-items: center;
        padding: 0px 5px 10px 5px;
        margin: 0 auto;
        h1 {
          color: #000000;
          text-shadow: -1px -1px 20px #00ff00;
          font-weight: 700;
          font-size: 50px;
          width: fit-content;
          line-height: 100%;
          margin-bottom: 0px;
          @media (max-width: 575px) {
            font-size: 40px;
          }
          @media (max-width: 414px) {
            font-size: 35px;
          }
        }
      }
    }
    .bg-image-heroland-content4 {
      @media (max-width: 991px) {
        background-size: cover;
      }
      .bg-content-typing {
        background-color: #00ff00;
        width: fit-content;
        height: 100%;
        position: relative;
        z-index: 2;
        align-items: center;
        padding: 0px 5px 10px 5px;
        margin: 0 auto;
        h1 {
          color: #000000;
          text-shadow: -1px -1px 20px #00ff00;
          font-weight: 700;
          font-size: 50px;
          width: fit-content;
          line-height: 100%;
          margin-bottom: 0px;
          @media (max-width: 575px) {
            font-size: 40px;
          }
          @media (max-width: 414px) {
            font-size: 35px;
          }
        }
      }
      .ourpartner {
        margin: 150px 0px 100px 0px;
        @media (max-width: 991px) {
          margin: 50px 0px 50px 0px;
        }
      }
      .bg-color {
        background-color: #00000092;
        &.build {
          padding: 150px 0px;
          @media (max-width: 991px) {
            padding: 50px 0px 50px 0px;
          }
        }
        .text-footer {
          color: #00ff00;
          font-size: 30px;
        }
      }
      .bg {
        background-color: #ffffff98;
        .contact-content {
          h5 {
            background-color: #000000;
            color: #ffffff;
            width: fit-content;
            padding: 2px 5px;
          }
          h3 {
            background-color: #000000;
            color: #ffffff;
            width: fit-content;
            padding: 2px 5px;
            font-weight: bold;
          }
        }
        a {
          p {
            color: #000000;
            font-weight: bold;
          }
        }
      }
      .partner {
        a {
          display: block;
          width: 100%;
          height: 100%;
          background-color: #ffffff;
          padding: 20px;
          border-radius: 15px;
          margin-top: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
#modal-center {
  .modal-body {
    height: 500px;
    padding: 0px;
    .youtube-section {
      height: 100%;
    }
  }
}
@keyframes tokencoin {
  25% {
    transform: translateY(-5%) rotateY(0);
  }
  50% {
    transform: translateY(-5%) rotateY(90deg);
  }
  75% {
    transform: translateY(-5%) rotateY(0);
  }
}
